$(function() {

    $(".s-case-carousel").owlCarousel({
        items: 3,
        loop: false,
        margin:10,
        nav:true,
        navText: ["<div class='custom-arrow custom-arrow--black custom-arrow--prev'></div>", "<div class='custom-arrow custom-arrow--black custom-arrow--next'></div>"],
        dots: false,
        responsive:{
            0:{
                items: 1,
                margin: 0
            },
           768:{
                items: 2,
                margin: 0
            },
            992:{
                items: 3
            }
        }
    });

});