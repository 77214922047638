$(function() {

    // $(".s-strategy-carousel").owlCarousel({
    //     items: 1,
    //     loop: false,
    //     margin:10,
    //     nav:true,
    //     navText: ["<div class='custom-arrow custom-arrow--black custom-arrow--prev'></div>", "<div class='custom-arrow custom-arrow--black custom-arrow--next'></div>"],
    //     dots: false,
    //     responsive:{
    //         0:{
    //             items:1
    //         },
    //         600:{
    //             items:1
    //         },
    //         1000:{
    //             items:1
    //         }
    //     }
    // });

});