$(function() {

    var testAnswer = $(".test__answer-main");
    $(".test__item").click(function() {
       // console.log($(this).index());
        $(".test__item").removeClass("active");
        $(this).addClass("active");
       testAnswer.hide();
       testAnswer.eq($(this).index()).fadeIn(400);
    });

    var share = Ya.share2('my-share', {
      content: {
        url: 'https://new-mar.ru/',
        title: 'NewMar',
        description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
        image: 'https://new-mar.ru/img/posting.png'
        },
        contentByService: {
          facebook: {
              url: 'https://new-mar.ru/',
              title: 'NewMar',
              description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
              image: 'https://new-mar.ru/img/posting.png'
          },
          gplus: {
            url: 'https://new-mar.ru/',
              title: 'NewMar',
              description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
              image: 'https://new-mar.ru/img/posting.png'
          },
          vkontakte: {
            url: 'https://new-mar.ru/',
            title: 'NewMar',
            description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
            image: 'https://new-mar.ru/img/posting.png'
          },
          odnoklassniki: {
            url: 'https://new-mar.ru/',
            title: 'NewMar',
            description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
            image: 'https://new-mar.ru/img/posting.png'
          },
          twitter: {
            url: 'https://new-mar.ru/',
            title: 'NewMar',
            description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
            image: 'https://new-mar.ru/img/posting.png'
          }
        }
      });

  var share = Ya.share2('my-share-2', {
    content: {
      url: 'https://new-mar.ru/',
      title: 'NewMar',
      description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
      image: 'https://new-mar.ru/img/posting.png'
      },
      contentByService: {
        facebook: {
            url: 'https://new-mar.ru/',
            title: 'NewMar',
            description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
            image: 'https://new-mar.ru/img/posting.png'
        },
        gplus: {
            url: 'https://new-mar.ru/',
            title: 'NewMar',
            description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
            image: 'https://new-mar.ru/img/posting.png'
        },
        vkontakte: {
          url: 'https://new-mar.ru/',
          title: 'NewMar',
          description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
          image: 'https://new-mar.ru/img/posting.png'
        },
        odnoklassniki: {
          url: 'https://new-mar.ru/',
          title: 'NewMar',
          description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
          image: 'https://new-mar.ru/img/posting.png'
        },
        twitter: {
          url: 'https://new-mar.ru/',
          title: 'NewMar',
          description: 'По рынку можно ехать на телеге, а мы предлагаем скоростные средства передвижения!',
          image: 'https://new-mar.ru/img/posting.png'
        }
      }
});


});