$(function() {

    new WOW().init();

    if( window.location.href.indexOf("contacts") != -1 ) {
        $("body").css({
            "display" : "flex",
            "flex-direction" : "column",
            "height" : "100vh"
        });
        $(".header").css({
           "flex" : "0 0 auto"
        });
        $("main").css({
           "flex" : "1 0 auto",
            "background" : "transparent " + $(".contacts").attr("style").split(":")[1] + " center bottom/cover no-repeat"
        });
        $(".contacts").removeAttr("style");
        $(".footer").css({
           "flex" : "0 0 auto"
        });
    }
    // $('input[type="tel"]').mask("+7(999) 999-99-99");

    // var radioValue = number_format($(this).val(), 0, ',', ' ');
    function number_format(number, decimals, dec_point, thousands_sep) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function(n, prec) {
                var k = Math.pow(10, prec);
                return '' + (Math.round(n * k) / k)
                    .toFixed(prec);
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
            .split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '')
            .length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1)
                .join('0');
        }
        return s.join(dec);
    }

    $(".contacts-form").submit(function(e) {
        var myform = $(this);
        var response2 = grecaptcha.getResponse();
        if(response2.length == 0) {
          console.log("recapча none");
          myform.find(".re-error").show();
          setTimeout(function() {
            myform.find(".re-error").hide();
          }, 3000);
          return false;
        }
        
        $.ajax({
          method: "POST",
          url: "send-mail",
          data: $(this).serialize(),
          beforeSend: function(  ) {
            
            
    
          }
        })
          .done(function( msg ) {
            // console.log( "Data Saved: " + msg );
            $.magnificPopup.open({
              items: {
                src: '#thx-request',
                type: 'inline',
                midClick: true,
              },
              callbacks: {
                beforeOpen: function() {
                  this.st.mainClass = "mfp-zoom-in";
                }
              },
            });
            myform.trigger('reset');
            grecaptcha.reset();
            console.log("done");
          })
          .fail(function() {
            console.log( "error" );
    
            setTimeout(function() {
    
              $.magnificPopup.close();
              $.magnificPopup.open({
                items: {
                  src: '#error-request',
                  type: 'inline',
                  midClick: true,
                },
                callbacks: {
                  beforeOpen: function() {
                    this.st.mainClass = "mfp-zoom-in";
                  }
                },
              });
              myform.trigger('reset');
    
            }, 700);
    
          })
          .always(function() {
            console.log( "complete" );
          });
          
        return false;
      });

      $(".form-feedback").submit(function(e) {
        var myform = $(this);
        var response2 = grecaptcha.getResponse();
        if(response2.length == 0) {
          console.log("recapча none");
          myform.find(".re-error").show();
          setTimeout(function() {
            myform.find(".re-error").hide();
          }, 3000);
          return false;
        }
        $.ajax({
          method: "POST",
          url: "review/store",
          data: $(this).serialize(),
          beforeSend: function(  ) {
            
            
    
          }
        })
          .done(function( msg ) {
            // console.log( "Data Saved: ");
            $.magnificPopup.open({
                items: {
                  src: '#thx-review', // can be a HTML string, jQuery object, or CSS selector
                  type: 'inline',
                  midClick: true,
                },
                callbacks: {
                  beforeOpen: function() {
                    this.st.mainClass = "mfp-zoom-in";
                    }
                },
              });
              myform.trigger('reset');
              grecaptcha.reset();
          })
          .fail(function() {
            console.log( "error" );
    
            setTimeout(function() {
    
              $.magnificPopup.close();
              $.magnificPopup.open({
                items: {
                  src: '#error-review',
                  type: 'inline',
                  midClick: true,
                },
                callbacks: {
                  beforeOpen: function() {
                    this.st.mainClass = "mfp-zoom-in";
                  }
                },
              });
              myform.trigger('reset');
    
            }, 700);
    
          })
          .always(function() {
            console.log( "complete" );
          });
    
        return false;
      });


      $(".form-request").submit(function(e) {
        var myform = $(this);
        var response2 = grecaptcha.getResponse();
        if(response2.length == 0) {
          console.log("recapча none");
          myform.find(".re-error").show();
          setTimeout(function() {
            myform.find(".re-error").hide();
          }, 3000);
          return false;
        }
        $.ajax({
          method: "POST",
          url: "send-mail",
          data: $(this).serialize(),
          beforeSend: function(  ) {
            
            
    
          }
        })
          .done(function( msg ) {
            // console.log( "Data Saved: " + msg );
            $.magnificPopup.open({
                items: {
                  src: '#thx-request',
                  type: 'inline',
                  midClick: true,
                },
                callbacks: {
                  beforeOpen: function() {
                    this.st.mainClass = "mfp-zoom-in";
                  }
                },
              });
              myform.trigger('reset');
            console.log("done");
          })
          .fail(function() {
            console.log( "error" );
    
            setTimeout(function() {
    
              $.magnificPopup.close();
              $.magnificPopup.open({
                items: {
                  src: '#error-request',
                  type: 'inline',
                  midClick: true,
                },
                callbacks: {
                  beforeOpen: function() {
                    this.st.mainClass = "mfp-zoom-in";
                  }
                },
              });
              myform.trigger('reset');
              grecaptcha.reset();
            }, 700);
    
          })
          .always(function() {
            console.log( "complete" );
          });
    
        return false;
      });

    
});