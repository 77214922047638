$(function() {


  //читать-скрыть отзыв
  if ( $(".reviews").length ) {

    $(".reviews__text-block").each(function() {
      if ( $(this).height() > 45 ) {
        $(this).addClass("oh");
      }else{
        $(this).next().hide();
      }
    });

    $('.reviews__read-more').click(function(e) {

      e.preventDefault();
      e.stopPropagation();

      if ( !$(this).hasClass("active") ) {

        // $(this).prev().animate({
        //   'height': $(this).prev()[0].scrollHeight
        // });
        $(this).addClass("active").text("Скрыть отзыв").prev().animate({
            'height': $(this).prev()[0].scrollHeight
          });

      }else{

        // $(this).prev().animate({
        //   'height': "45px"
        // });
        $(this).removeClass("active").text("Читать весь отзыв").prev().animate({
          'height': "45px"
        });

      }
      
    });

  }

});