$(function() {
    $(".a-partners-carousel").owlCarousel({
        items: 3,
        loop: false,
        margin:10,
        nav:true,
        navText: ["<div class='custom-arrow custom-arrow--black custom-arrow--prev'></div>", "<div class='custom-arrow custom-arrow--black custom-arrow--next'></div>"],
        dots: false,
        responsive:{
            0:{
                items:1,
                margin: 0
            },
            768:{
                items:2
            },
            1200:{
                items:3
            }
        }
    });
});
