$(function() {

    $(".partners__carousel").owlCarousel({
       items: 5,
        loop: false,
        margin:10,
        nav:true,
        navText: ["<div class='custom-arrow custom-arrow--prev'></div>", "<div class='custom-arrow custom-arrow--next'></div>"],
        dots: false,
        // autoHeight: true,
        responsive:{
            0:{
                items: 1,
                margin:0
            },
            400: {
              items: 2
            },
            600:{
                items: 3
            },
            900: {
                items: 4
            },
            1200:{
                items: 5
            }
        }
    });

});