$(function() {
   //
   // $(".lang-block").on("click", function() {
   //     if ( $(this).find(".lang-block-list").is(":visible") ) {
   //         $(this).find(".lang-block-list").fadeOut(200);
   //     }else{
   //         $(this).find(".lang-block-list").fadeIn(200);
   //     }
   // });
   //
   // var overlay = $(".overlay");
   // var mainMenu = $(".main-menu");
   // $(".hamburger").on("click", function() {
   //    $(this).toggleClass("open");
   //    overlay.fadeIn(200);
   //    // $("body").toggleClass("no-scroll");
   //    if ( $(this).hasClass("open") ) {
   //        if ($(document).height() > $(window).height()) {
   //            var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
   //            $('html').addClass('no-scroll').css('top',-scrollTop);
   //        }
   //        overlay.fadeIn(200);
   //        setTimeout(function() {
   //            mainMenu.fadeIn(200);
   //        }, 200);
   //    }else{
   //        var scrollTop = parseInt($('html').css('top'));
   //        $('html').removeClass('no-scroll');
   //        $('html,body').scrollTop(-scrollTop);
   //        mainMenu.fadeOut(200);
   //        overlay.fadeOut(200);
   //    }
   // });
   //
   // $(".user").on("click", function() {
   //      if ( $(window).width() < 900 && $(".header").hasClass("pa-header") ) {
   //          $(".pa-aside").fadeIn(200);
   //          $("body").addClass("no-scroll");
   //          return false;
   //      }
   // });
   // $(".pa-aside__close").on("click", function() {
   //     $(".pa-aside").fadeOut(200);
   //     $("body").removeClass("no-scroll");
   // });
   //
   // $(window).resize(function() {
   //    if ($(window).width() > 899 ) {
   //        $(".pa-aside").removeAttr("style");
   //        $(".main-menu").removeAttr("style");
   //    }
   // });
   //  // $(".hero-info-footer__btn").fancybox({
   //  //     "width": 620,
   //  //     "height": 420,
   //  //    "type": "iframe"
   //  // });
   //
   //  if ( !$(".pa-header").length ) {
   //      $(".main-menu__link").on("click", function() {
   //          //for no-scroll
   //          var scrollTop = parseInt($('html').css('top'));
   //          $('html').removeClass('no-scroll');
   //          $('html,body').scrollTop(-scrollTop);
   //          $('html').removeClass('no-scroll');
   //
   //          var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .
   //
   //          //for mobile
   //          if ( $(window).width() <= 768 ) {
   //              $(".overlay").fadeOut(200);
   //              $(".main-menu").fadeOut(200);
   //              $(".hamburger").removeClass("open");
   //          }
   //          if ($(scroll_el).length != 0) { // проверим существование элемента чтобы избежать ошибки
   //              $('html, body').animate({ scrollTop: $(scroll_el).offset().top }, 500); // анимируем скроолинг к элементу scroll_el
   //          }
   //          return false; // выключаем стандартное действие
   //      });
   //  }else {
   //
   //  }
   //
   //  if ( location.hash ) {
   //      // window.scrollTo(0, 0);
   //      // var locHash = location.hash;
   //      // console.log("animate");
   //      // setTimeout(function() {
   //      // $('html, body').stop().animate({
   //      //     'scrollTop': window.pageYOffset
   //      // });
   //      //     $('html, body').stop().animate({ scrollTop: $(locHash).offset().top }, 1500);
   //      // }, 1000);
   //
   //  }
   //
   //

    // скролл с кнопки Подать заявку к самой заявке
    $(".contacts-block__btn").click(function(e) {
        if ($(".request").length) {
            $("html, body").animate({scrollTop: $(".request").offset().top+"px"});
            return false;
        }
    });
    $(".inner-btn").click(function(e) {
      if ($(".request").length) {
        $("html, body").animate({scrollTop: $(".request").offset().top+"px"});
        return false;
      }
    });

});

